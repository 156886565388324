import War3MapViewer from "mdx-m3-viewer/dist/cjs/viewer/handlers/w3x/viewer";

import PartySocket from "partysocket";
import { setupCamera } from "./camera";
import War3MapUnitsDoo from "mdx-m3-viewer/dist/cjs/parsers/w3x/unitsdoo/file";
import type MdxModel from "mdx-m3-viewer/dist/cjs/viewer/handlers/mdx/model";
import UnitDoo from "mdx-m3-viewer/dist/cjs/parsers/w3x/unitsdoo/unit";
import Unit from "mdx-m3-viewer/dist/cjs/viewer/handlers/w3x/unit";
import { vec3 } from "gl-matrix";

declare const PARTYKIT_HOST: string;


// You can even start sending messages before the connection is open!

const pathSolver = (src: unknown) =>
  `https://www.hiveworkshop.com/casc-contents?path=${(
    src as string
  ).toLowerCase()}`;

const canvas = document.getElementById("game") as HTMLCanvasElement;

const viewer = new War3MapViewer(canvas, pathSolver, true);

(function step() {
  requestAnimationFrame(step);
  viewer.updateAndRender();
})();

const mapBuffer = fetch("/maps/Sample.w3m").then((response) =>
  response.arrayBuffer()
);

const random = (min: number, max: number) => Math.random() * (max - min) + min;
let x = random(-1000, -350);
let y = random(-1500, -1000);



(viewer as any).on("loadedbasefiles", async () => {
  viewer.loadMap(await mapBuffer);
  setupCamera(viewer.map?.worldScene, { distance: 3000 });

  if (!viewer.map) throw new Error("Map not loaded");

  let players = new Map<string, Unit>();

  const model = (await viewer.map.load(
    "units\\human\\Peasant\\Peasant.mdx"
  )) as MdxModel;
  const unitDoo = new UnitDoo();
  unitDoo.id = unitDoo.skin = "hpea";
  unitDoo.flags = 2;
  unitDoo.player = 1;
  unitDoo.goldAmount = 12500;
  unitDoo.targetAcquisition = -1;
  unitDoo.heroLevel = 1;
  unitDoo.level = new Uint8Array([1, 0, 0]);
  unitDoo.customTeamColor = -1;
  unitDoo.waygate = -1;
  unitDoo.creationNumber = 5;

  // A PartySocket is like a WebSocket, except it's a bit more magical.
  // It handles reconnection logic, buffering messages while it's offline, and more.
  const conn = new PartySocket({
    host: PARTYKIT_HOST,
    room: "my-new-room",
  });

  function movePlayer(id: string, x: number, y: number) {
    const player = players.get(id);
    if (player) {
      player.instance.setLocation([x, y, 0]);
    } else {
      unitDoo.location = new Float32Array([x, y, 0]);
      const unit = new Unit(
        viewer.map!,
        model,
        viewer.unitsData.getRow("hpea"),
        unitDoo
      );
      players.set(id, unit);
      viewer.map?.units.push(unit);
    }
  }

  function move(deltaX: number = 0, deltaY: number = 0) {
    x += deltaX;
    y += deltaY;
    console.log(`Sending -> ${JSON.stringify({ x, y })}`);
    conn.send(JSON.stringify({ x, y }));
  }
  
  // Let's listen for when the connection opens
  // And send a ping every 2 seconds right after
  conn.addEventListener("open", () => {
    console.log("Connected!");
    console.log("Sending a ping every 2 seconds...");
    move();
  });

  conn.addEventListener("message", (event) => {
    console.log(`Received -> ${event.data}`);
    const result = JSON.parse(event.data);
    if (Array.isArray(result)) {
      result.forEach(({ id, x, y }) => movePlayer(id, x, y));
    } else {
      movePlayer(result.id, result.x, result.y);
    }
  });

  document.addEventListener("keypress", (e) => {
    switch (e.code) {
      case "KeyW":
        move(0, 100);
        break;
      case "KeyS":
        move(0, -100);
        break;
      case "KeyA":
        move(-100, 0);
        break;
      case "KeyD":
        move(100, 0);
        break;
    }
  });
});
